import { Trans } from "@lingui/macro";
import { Stack, Typography } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import React from "react";
import policiesStatementsDocs from "../../cms/policiesStatementsDocs/policiesStatementsDocs";
import BoxDetails from "../../components/BoxDetails";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";

const buttons = () => [
  {
    value: "environment",
    label: <Trans>Environment</Trans>,
    byFilter: "Environmental",
  },
  {
    value: "social",
    label: <Trans>Social</Trans>,
    byFilter: "Social",
  },
  {
    value: "governance",
    label: <Trans>Governance</Trans>,
    byFilter: "Governance",
  },
];

function PoliciesStatementsPage() {
  const { locale } = useLocalization();
  const docs = policiesStatementsDocs[locale];

  return (
    <NewLayout byDefault apps="esg.policies_statements">
      <Container pb={8}>
        <Typography mt={3} mb={6} variant="h2">
          <Trans>Policies And Statements</Trans>
        </Typography>
        <Stack spacing={2}>
          {buttons().map(({ label, byFilter }) => {
            const items = docs.filter(
              ({ attributes: { category } }) => category === byFilter,
            );
            return <BoxDetails title={label} items={items} />;
          })}
        </Stack>
      </Container>
    </NewLayout>
  );
}

export default PoliciesStatementsPage;
